@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  background: linear-gradient(to bottom,
      transparent,
      rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb));
  font-size: 16px;
}

.go-btn {
  font-weight: 300;
  white-space: nowrap;
  font-size: 20px;
  color: #fff;
  border: 0;
  z-index: 0;
  background: linear-gradient(45deg, #2c6dd5 0%, #2c6dd5 28%, #ff4b5a 91%, #ff4b5a 100%);
}

.css-1u9des2-indicatorSeparator {
  display: none;
}


li > ul {
  transform: translatex(100%) scale(0);
}
li:hover > ul {
  transform: translatex(101%) scale(1);
}
li > button svg {
  transform: rotate(-90deg);
}
li:hover > button svg {
  transform: rotate(-270deg);
}

.filter-modal input{
  background-color: white !important;
}



th {
  border-radius: 0 !important;
}

.arrow-tail::before {
  content: '';
  bottom: 100%;
  left: 50%;
  border-style: solid;
  border-width: 0 0.5rem 0.5rem 0.5rem; /* Adjust size of the arrow here */
  transform: translateX(-50%);
}

.marquee-right {
  display: inline-block;
  animation: marquee_right 45s linear infinite alternate;
}

.marquee-right-content {
  animation: marquee_right_content 45s linear infinite alternate;
  white-space: nowrap;
}

@keyframes marquee_right {
  0% { margin-left:0; }
  100% { margin-left: 100%; }
}
@keyframes marquee_right_content {
  0% { margin-left:0; }
  100% { margin-left:-100%; }
}

.marquee-left {
  display: inline-block;
  animation: marquee_left 45s linear infinite alternate;
}

.marquee-left-content {
  animation: marquee_left_content 45s linear infinite alternate;
  white-space: nowrap;
}

@keyframes marquee_left {
  0% { margin-right:0; }
  100% { margin-right: 100%; }
}
@keyframes marquee_left_content {
  0% { margin-right:0; }
  100% { margin-left: 100%; }
}

.bg-data-access-row1 {
  background-color: #262626;
}
